import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as md5 from 'md5';
import Alert from "./Alert";

function Header(props) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const gravatarHash = props.signedInAs && md5(props.signedInAs.trim().toLowerCase());
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setDropdownVisible(false);
    // HACK: Can't use `useLocation()` in App.js
    props.setFlashVisible(false);
  }, [location]);

  const logout = () => {
    window.localStorage.clear();
    props.setFlash("Logged out!");
    navigate("/");
    setDropdownVisible(false);
    props.setSignedInAs(null);
    props.setToken(null);
  };

  return (
    <div className="w-full px-4 py-2 flex mt-2">
      <div className="flex-none mt-2 ml-6">
          <Link to="/" className="hover:text-gray-400 text-gray-900 text-xl font-bold">kindle epub</Link>
          <span className="text-sm text-gray-300 font-medium ml-1">alpha</span>
      </div>

      <div className="flex-grow"></div>

      <div className="flex-none flex">
        <a className="self-center mr-6 text-slate-800 hover:underline" href="https://status.kindleepub.com" target="_blank">Status</a> 
        {props.signedInAs 
          ? <>
            <button onClick={() => setDropdownVisible(!dropdownVisible)}>
              <img className="h-8 w-8 rounded-full" src={`https://www.gravatar.com/avatar/${gravatarHash}`} alt="" />
            </button>
            <div className={`z-10 mt-8 relative ${dropdownVisible || "hidden"}`}>
              <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                <span href="#" className="cursor-default text-center block w-full px-4 py-2 text-sm text-gray-500 border-b truncate" role="menuitem" tabIndex="-1">{props.signedInAs}</span>
                <Link to="/settings" className="block w-full px-4 py-2 text-sm hover:bg-gray-100 text-gray-700 text-center">Settings</Link>
                <button onClick={() => logout()} className="block w-full px-4 py-2 text-sm hover:bg-gray-100 text-gray-700" role="menuitem" tabIndex="-1">Sign out</button>
              </div>
            </div>
          </>
          : <>
            <Link to="/login" className="self-center mr-6 text-slate-900 font-bold hover:underline">Sign In</Link>
            <Link
              to="/signup"
              className='bg-gray-900 hover:bg-gray-50 hover:text-gray-900 border-2 border-gray-900 text-gray-50 px-4 py-1 rounded shadow'>
              Sign Up
            </Link>
          </>}
        </div>
    </div>
  );
}

export default Header;