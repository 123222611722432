import { useEffect, useState } from 'react';

function Alert(props) {
  let icon, border, bg, headlineFg, textFg;

  if (props.style === 'green') {
    icon = <svg xmlns="http://www.w3.org/2000/svg" className="w-6 -ml-0.5 mr-1" fill="#000000" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none"></rect>
      <circle className="fill-green-500" cx="128" cy="128" r="96" stroke="#000000" strokeLinejoin="round" strokeWidth="0"></circle>
      <polyline points="172 104 113.3 160 84 132" fill="none" stroke="#FFF" strokeLinejoin="round" strokeWidth="16"></polyline>
    </svg>;

    bg = 'bg-green-200';
    border = 'border-green-400';
    headlineFg = 'text-green-900';
    textFg = 'text-green-800';
  }

  if (props.style === 'yellow') {
    icon = <svg xmlns="http://www.w3.org/2000/svg" className="w-6 -ml-0.5 mr-1" fill="#000000" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none"></rect>
      <circle className="fill-amber-400" cx="128" cy="128" r="96" fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="0"></circle>
      <line x1="128" y1="80" x2="128" y2="136" fill="none" className="stroke-gray-100" strokeLinejoin="round" strokeWidth="16"></line>
      <circle className="fill-gray-100" cx="128" cy="172" r="12"></circle>
    </svg>;

    bg = 'bg-yellow-200';
    border = 'border-yellow-400';
    headlineFg = 'text-yellow-900';
    textFg = 'text-yellow-800';
  }

  if (props.style === 'red') {
    icon = <svg xmlns="http://www.w3.org/2000/svg" className="w-6 -ml-0.5 mr-1" fill="#000000" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none"></rect>
      <circle cx="128" cy="128" r="96" className="fill-red-500" fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="0"></circle>
      <line x1="160" y1="96" x2="96" y2="160" className="stroke-gray-100" strokeLinejoin="round" strokeWidth="16"></line>
      <line x1="160" y1="160" x2="96" y2="96" fill="none" className="stroke-gray-100" stroke="#000000" strokeLinejoin="round" strokeWidth="16"></line>
    </svg>

    bg = 'bg-rose-100';
    border = 'border-rose-200';
    headlineFg = 'text-rose-900';
    textFg = 'text-rose-800';
  }

  if (props.visible === false) {
    return <></>;
  }

  return (
    <div className={`${bg} ${border} border px-6 py-4 rounded my-6`}>
      <div className="flex">
        {icon}
        <div className={`font-semibold ${headlineFg} text-sm mt-0.5`}>
          {props.headline}
        </div>
        {props.dismissable && <>
          <div className="flex-grow"></div>
          <button onClick={() => props.setVisible(false)} className="hover:text-green-900 hover:font-medium text-green-700">✗</button>
        </>}
      </div>

      {props.text &&
        <div className={`${textFg} text-xs mt-2`}>
          {props.text}
        </div>
      }
    </div>
  );
}

export default Alert;