import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import Alert from "./Alert";
import { API_SERVER_BASE } from "./constants";
import Spinner from "./Spinner";
import * as moment from 'moment';

function Settings(props) {
  const [emails, setEmails] = useState(null);
  const [conversions, setConversions] = useState(null);
  const [draft, setDraft] = useState('');
  const [error, setError] = useState(null);
  const [network, setNetwork] = useState(false);

  useEffect(() => {
    if (!props.token) {
      return;
    }

    async function get() {
      // TODO: Error handling
      // TODO: Combine - GraphQL?
      const maybeEmails = axios.get(`${API_SERVER_BASE}/api/delivery_emails`, {
        headers: {
          'Authorization': props.token
        }
      });

      const maybeConversions = axios.get(`${API_SERVER_BASE}/api/conversions`, {
        headers: {
          'Authorization': props.token
        }
      });

      const [emailsResponse, conversionsResponse] = await Promise.all([maybeEmails, maybeConversions]);

      const emails = emailsResponse.data.map(e => e.Email);
      const conversions = conversionsResponse.data.map(c => c);
      setEmails(emails);
      setConversions(conversions);
      console.log(conversions);
    }

    get();

  }, [props.token]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveNew();
    }
  }

  const formValidationErrors = () => {
    const conditions = [
      [!draft || _.trim(draft.length) === 0, "Enter an email address"],
      [draft && !draft.includes('@'), "Enter a valid email address"],
    ];

    return conditions.flatMap(([c, msg]) => c ? msg : []);
  }

  const deleteExisting = async (email) => {
    setError(null);

    try {
      setNetwork(true);
      const user = await axios.delete(`${API_SERVER_BASE}/api/delivery_emails`, {
        headers: { 'Authorization': props.token },
        data: { email: email }
      });
      setEmails(_.without(emails, email));
      props.setFlash(`Removed ${email}!`)
    } catch (e) {
      setError(e.response ? e.response.data : e.toString());
    } finally {
      setNetwork(false);
    }
  }

  const saveNew = async () => {
    setError(null);

    const formErrors = formValidationErrors();
    if (formErrors.length > 0) {
      setError(<ul className="list-disc ml-4">
        {formErrors.map((e) => <li key={e}>{e}</li>)}
      </ul>);
      return;
    }

    try {
      setNetwork(true);
      const user = await axios.post(`${API_SERVER_BASE}/api/delivery_emails`, { email: draft }, {
        headers: { 'Authorization': props.token }
      });
      setEmails([...emails, draft])
      setDraft('');
      props.setFlash(`Added ${draft}!`)
    } catch (e) {
      setError(e.response ? e.response.data : e.toString());
    } finally {
      setNetwork(false);
    }
  };

  const listItemClassName = (i, n) => {
    let cl = "flex items-center justify-between px-4 truncate py-2 border-t border-l border-r ";

    if (i === 0) {
      cl += "border-t rounded-t"
    }

    if (i === n-1) {
      cl += "border-b rounded-b"
    }

    return cl;
  };

  return (
    <div className="my-8">
      {error && <Alert style='red' headline='Failed to process your submission' text={error} />}

      <h1 className="text-xl font-medium mb-4">Send-to-Kindle Email Addresses</h1>
      {emails
        ? <ul className="">
            {emails.map((e, i) => <li key={i} className={listItemClassName(i, emails.length)}>
              <span>{e}</span>
              <button onClick={() => deleteExisting(e)} className="text-gray-500 font-bold">✗</button>
            </li>)}
          </ul>
        : <div className="italic text-gray-400">Loading…</div>}

      <div className="w-full flex mt-4 items-stretch">
        <input onKeyDown={onKeyDown} value={draft} onChange={(e) => setDraft(e.target.value)} disabled={network} className="italic text-sm px-4 flex-grow border rounded" type="text" placeholder="Add new Send-to-Kindle email address"></input>
          <button onClick={() => saveNew()} disabled={network} className="disabled:bg-gray-600 flex ml-4 rounded hover:bg-gray-700 px-6 text-center bg-gray-800 py-2 text-slate-100 text-xl font-light">
            {network && <Spinner className="animate-spin w-5 mr-2" />}
            Add
          </button>
      </div>

      <h1 className="text-xl font-medium mb-4 mt-16">Conversions</h1>
      {conversions
        ? <table className="w-full border-collapse border border-slate-400 table-auto">
            <thead>
              <tr className="truncate bg-gray-50 text-sm">
                <th className="px-4 py-2 border font-semibold text-left border-slate-300">Filename</th>
                <th className="px-4 py-2 border font-semibold text-left border-slate-300">Delivered To</th>
                <th className="px-4 py-2 border font-semibold text-left border-slate-300">Finished At</th>
              </tr>
            </thead>
            <tbody>
              {conversions.map((c, i) => <tr key={i} className="text-sm">
                <td className="px-4 truncate py-2 border border-slate-300">{c.Filename}</td>
                <td className="px-4 truncate py-2 border border-slate-300">{c.DeliveryEmail.Email}</td>
                {c.CompletedAt.Valid
                  ?  <td className="px-4 truncate py-2 border border-slate-300">{moment.utc(c.CompletedAt.Time).local().format("D MMM YYYY, h:mmA")}</td>
                  :  <td className="px-4 truncate py-2 border italic text-slate-400 border-slate-300">In Progress…</td>}
              </tr>)}
            </tbody>
          </table>
        : <div className="italic text-gray-400">Loading…</div>}
    </div>
  );
}

export default Settings;