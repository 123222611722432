import _ from 'lodash';

function ConvertProgress(props) {
  return (
    <div className="text-sm text-slate-400 overflow-clip">
      <pre>
        {props.logs.slice(0).reverse().map((l, i) => {
          return <code key={i} className="font-mono">
            - {l}
            <br />
          </code>
        })}
      </pre>
    </div>
  );
}

export default ConvertProgress;