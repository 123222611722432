import { createRef, useEffect, useRef, useState } from "react";
import axios from 'axios';
import Spinner from './Spinner';
import _ from 'lodash';
import {API_SERVER_BASE} from './constants';
import CreatableSelect from 'react-select/creatable'

const MEGABYTE = 1024 * 1024;
const MAX_UPLOAD = 15 * MEGABYTE;

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

function ConvertSettings(props) {
  let buttonLabel = "Convert…";
  let fileRef = useRef(null);

  let [deliveryEmails, setDeliveryEmails] = useState(null);
  let [email, setEmail] = useState(null);

  switch (props.status) {
    case 'uploading':
      buttonLabel = 'Uploading...';
      break;
    case 'converting':
      buttonLabel = 'Converting...';
      break;
    default:
  }

  const isJobDone = async (id) => {
    // TODO: Error handling
    const poll = await axios.get(`${API_SERVER_BASE}/api/jobs/${id}`, {
      'headers': {'Authorization': props.token}
    });
    props.setLogs(poll.data.logs);

    if (poll.data.err) {
      props.setStatus('failure');
      props.setErrorMessage(poll.data.err);
    } else if (poll.data.done) {
      props.setStatus('success');
    }
     
    return poll.data.done;
  };

  const convert = async () => {
    const formErrors = formValidationErrors();

    if (formErrors.length > 0) {
      props.setStatus('invalid');
      props.setValidationErrors(formErrors);
      return;
    }

    props.setLogs([]);
    props.setStatus("uploading");

    let file = fileRef.current.files[0];

    var formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    formData.append('email', email.value);

    let response;
    try {
      response = await axios.post(`${API_SERVER_BASE}/api/convert`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': props.token
        }
      });
    } catch (e) {
      props.setStatus('failure');
      props.setErrorMessage(e.response ? e.response.data : e.toString())
      return;
    }

    props.setStatus("converting");

    try {
      while (!(await isJobDone(response.data.id))) {
        await sleep(2000);
      }
    } catch (e) {
      // TODO
      props.setStatus('failure');
      props.setErrorMessage(e.toString())
    }
  };

  const formValidationErrors = () => {
    const currentFile = fileRef.current && fileRef.current.files.length > 0 && fileRef.current.files[0];

    const conditions = [
      [!email || _.trim(email.length) === 0, "Enter a Send-to-Kindle email address"],
      [email && !email.value.includes('@'), "Enter a valid Send-to-Kindle email address"],
      [!currentFile, "Select an EPUB file to upload"],
      [currentFile && currentFile.size > MAX_UPLOAD, `Only files smaller than ${MAX_UPLOAD/MEGABYTE}MB are supported at the moment`]
    ];

    return conditions.flatMap(([c, msg]) => c ? msg : []);
  }

  useEffect(() => {
    if (!props.token) {
      return;
    }

    async function get() {
      // TODO: Error handling
      const response = await axios.get(`${API_SERVER_BASE}/api/delivery_emails`, {
        headers: {
          'Authorization': props.token
        }
      });

      const emails = response.data.map((e, i) => {
        return { value: e.Email, label: e.Email }
      });

      setDeliveryEmails(emails);
      setEmail(emails[0]);
    }

    get();

  }, [props.token]);


  return (
    <div className="border-2 border-slate-200 rounded px-8 pt-6 pb-8 mb-4 mt-8">
      <form className="">
        <div className="mb-4">
          <label className="block text-slate-700 text-sm font-bold mb-2" htmlFor="username">
            Send-to-Kindle Email Address
          </label>
          <CreatableSelect 
            options={deliveryEmails} 
            isLoading={!deliveryEmails} 
            isDisabled={!deliveryEmails}
            onChange={(v) => setEmail(v)}
            value={email} />
        </div>
        <div className="my-8">
          <label className="block text-gray-700 text-sm font-bold" htmlFor="password">
            EPUB File
          </label>
          <input ref={fileRef} 
                 className="w-full rounded border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                 id="file" 
                 accept=".epub,.mobi"
                 type="file" />
        </div>
        <button type="button"
                onClick={convert}
                disabled={props.status === 'uploading' || props.status === 'converting'}
                className="mt-2 rounded disabled:bg-gray-500 hover:bg-gray-700 w-full text-center bg-gray-800 py-3 text-slate-100 text-xl font-light">

          <div className="flex justify-center">
            {(props.status === 'uploading' || props.status === 'converting') && 
              <Spinner className="animate-spin h-6 w-6 mr-3 mt-0.5 text-gray-200" />
            }

            {buttonLabel}
          </div>
        </button>
      </form>
    </div>
  );
}

export default ConvertSettings;