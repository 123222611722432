import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import Alert from './Alert';
import './App.css';
import { API_SERVER_BASE } from './constants';
import ConvertProgress from './ConvertProgress';
import ConvertResult from './ConvertResult';
import ConvertSettings from './ConvertSettings';
import Header from './Header';
import Login from './Login';
import Settings from './Settings';
import Signup from './Signup';


function App() {
  // initial, invalid, uploading, converting, success, failure
  const [status, setStatus] = useState("initial");
  const [logs, setLogs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [token, setToken] = useState(null);
  const [signedInAs, setSignedInAs] = useState(localStorage.getItem('signedInAs'));

  const [flashMessage, setFlashMessage] = useState(null);
  const [flashVisible, setFlashVisible] = useState(true);

  const setFlash = (message) => {
    setFlashMessage(message);
    setFlashVisible(true);
  }


  useEffect(() => {
    async function createAnonymousUser() {
      // TODO: Error handling
      const user = await axios.post(`${API_SERVER_BASE}/api/createAnonUser`);
      console.debug("Creating anonymous user…");
      localStorage.setItem('token', user.data.token);
      setToken(user.data.token);
    }

    const maybeToken = localStorage.getItem('token');
    if (maybeToken && maybeToken.trim() !== '') {
      console.debug("Not creating anonymous user, localStorage has a token already…")
      setToken(maybeToken);
    } else {
      createAnonymousUser();
    }
  }, [token]);

  return (
    <Router>
      <div className="container mx-auto px-4">
        <Header signedInAs={signedInAs} setSignedInAs={setSignedInAs} setToken={setToken} setFlash={setFlash} setFlashVisible={setFlashVisible} />
        <div className="mx-auto max-w-2xl">

          {flashMessage && flashMessage.trim() !== '' && <Alert
            style="green"
            headline={flashMessage}
            dismissable={true}
            visible={flashVisible}
            setVisible={setFlashVisible}
          />}

          <Routes>
            <Route exact path="/" element={<>
              <ConvertSettings token={token} status={status} setStatus={setStatus} setLogs={setLogs} setErrorMessage={setErrorMessage} setValidationErrors={setValidationErrors} />
              <ConvertResult status={status} errorMessage={errorMessage} validationErrors={validationErrors} />
              <ConvertProgress logs={logs} />
            </>} />

            <Route exact path="/login" element={<Login token={token} setSignedInAs={setSignedInAs} setToken={setToken} setFlash={setFlash} />} />
            <Route exact path="/signup" element={<Signup token={token} setSignedInAs={setSignedInAs} setFlash={setFlash} />} />
            <Route exact path="/settings" element={<Settings token={token} setFlash={setFlash} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
