import axios from "axios";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert";
import { API_SERVER_BASE } from "./constants";

function Signup(props) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState([]);
  const [requestFailed, setRequestFailed] = useState(null);
  const navigate = useNavigate();

  const formValidationErrors = () => {
    const conditions = [
      [!email || _.trim(email.length) === 0, "Enter an email address"],
      [email && !email.includes('@'), "Enter a valid email address"],
      [!password || _.trim(password.length) === 0, "Enter a password"],
    ];

    return conditions.flatMap(([c, msg]) => c ? msg : []);
  }

  const submitForm = async () => {
    const formErrors = formValidationErrors();

    setRequestFailed(null);
    setErrors(formErrors);

    if (formErrors.length > 0) {
      return;
    }

    let headers = {};
    if (props.token) {
      headers['Authorization'] = props.token;
    }


    try {
      const result = await axios.post(`${API_SERVER_BASE}/api/signup`, {
        email: email,
        password: password
      }, { headers: headers });
    } catch (e) {
      setRequestFailed(e.response.data);
      return;
    }

    localStorage.setItem('signedInAs', email);
    props.setSignedInAs(email);

    props.setFlash(`Created user account for ${email}!`);

    navigate("/");
  };

  return (
    <div className="max-w-lg mx-auto">
      <div className="font-light text-3xl mt-8 mx-auto">Sign Up</div>
      <div className="border-2 border-slate-200 rounded px-8 pt-6 pb-8 mb-4 mt-3">
        <form>
          <div className="mb-8">
            <label className="block text-slate-700 text-sm font-bold mb-2" htmlFor="username">
              Email
            </label>
            <input className="rounded border-gray-200 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                   id="email" type="email"
                   onChange={(e) => setEmail(e.target.value)}
                   placeholder="foo@example.com" />
          </div>
          <div className="mb-4">
            <label className="block text-slate-700 text-sm font-bold mb-2" htmlFor="username">
              Password
            </label>
            <input className="rounded border-gray-200 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                   id="password" type="password"
                   onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="flex">
            <button type="button"
                    onClick={() => submitForm()}
                    className="mt-6 rounded hover:bg-gray-700 px-24 text-center bg-gray-800 py-3 text-slate-100 text-xl font-light shadow">
                Submit
            </button>
          </div>
        </form>
      </div>

      {errors.length > 0 && <Alert
        style='yellow'
        headline='There were errors with your submission'
        text={<ul className="list-disc ml-4">{errors.map((e) => <li key={e}>{e}</li>)}</ul>}
      />}

      {requestFailed && <Alert
        style='red'
        headline='Signup failed with error:'
        text={requestFailed}
      />}
    </div>
  );
}

export default Signup;