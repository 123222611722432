import Alert from "./Alert";

function ConvertResult(props) {
  if (props.status === 'success') {
    return <Alert 
              style='green' 
              headline="Conversion completed" 
              text="Your file has been converted and sent to your Kindle device. Please allow up to five minutes for delivery, although this usually takes under a minute." />
  }

  if (props.status === 'failure') {
    return <Alert 
              style='red' 
              headline="Conversion failed" 
              text={"Conversion failed with: " + props.errorMessage} />
  }

  if (props.status === 'invalid') {
    const errorsList = <ul className="list-disc ml-4">
      {props.validationErrors.map((e) => {
        return <li key={e}>{e}</li>;
      })}
    </ul>;

    let headline;
    if (props.validationErrors.length === 1) {
      headline = `There was ${props.validationErrors.length} error with your submission`;
    } else {
      headline = `There were ${props.validationErrors.length} errors with your submission`;
    }

    return <Alert 
              style='yellow' 
              headline={headline}
              text={errorsList} />
  }

  return (
    <div></div>
  );
}

export default ConvertResult;